* {
    margin: 0;
    padding: 0;
    border: 0;
    vertical-align: baseline;
    background: transparent;
    font-weight: normal;
    font-style: normal;
    text-decoration: none;
    outline: none;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
}
ol,
ul {
    list-style: none;
}
blockquote {
    quotes: none;
}
blockquote:before,
blockquote:after {
    content: "";
    content: none;
}
del {
    text-decoration: line-through;
}
header,
section,
article,
aside,
nav,
footer,
address {
    display: block;
}
body {
    font-family: "product_sansregular";
    font-size: 17px;
    color: #333;
    overflow-x: hidden;
    min-width: 320px;
}
input,
textarea,
select {
    font-family: "product_sansregular";
}
a {
    color: #333;
}
a:hover,
.submit:hover {
    filter: alpha(opacity = 85);
    -moz-opacity: 0.85;
    -khtml-opacity: 0.85;
    opacity: 0.85;
}
.thumb {
    display: block;
}
.thumb img {
    display: block;
    width: 100%;
}
p {
    line-height: 1.6em;
    font-size: 16px;
    font-family: "product_sansregular";
}
.hidden {
    display: none;
}
br.clear {
    clear: both;
    line-height: 0;
}
.wrapper {
    width: 90%;
    margin: 0 auto;
    max-width: 1200px;
}
p,
h1,
h2,
a,
input {
    font-family: "product_sansregular";
    color: #fff;
}
input::-webkit-input-placeholder {
    color: #ffffff96;
}
input:-moz-placeholder {
    color: #ffffff96;
}
@font-face {
    font-family: "product_sansregular";
    src: url("../fonts/productsans/productsans-regular-webfont.woff2")
            format("woff2"),
        url("../fonts/productsans/productsans-regular-webfont.woff")
            format("woff");
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: "product_sansbold";
    src: url("../fonts/productsans/productsans-bold-webfont.woff2")
            format("woff2"),
        url("../fonts/productsans/productsans-bold-webfont.woff") format("woff");
    font-weight: normal;
    font-style: normal;
}
#spotlight {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #9f51b0;
    height: 100vh;
}
#spotlight section.card {
    background: #ffffff;
    width: 65%;
    padding: 22px 0 ;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    text-align: center;
}
#spotlight section.card p.initiative a{
    color: #000;
    font-weight: 600;
}
#spotlight section.card h1 {
    width: 24%;
}
#spotlight section.card h1 a.thumb {
}
#spotlight section.card h1 a.thumb img {
    margin: unset;
    width: 100%;
}
#spotlight section.card img {
    display: block;
    width: 34%;
}
#spotlight section.card p {
    font-size: 28px;
    color: #444444;
    letter-spacing: 0.08rem;
    margin: 30px 0 20px;
}
#spotlight section.card span.button {
    font-family: product_sansbold;
    background: #f5e9f7;
    padding: 20px 120px;
    color: #9f51b0;
    font-size: 27px;
    letter-spacing: 0.08rem;
    border-radius: 36px;
    display: inline-block;
}
@media (max-width: 1280px) {
    #spotlight section.card {
        padding: 37px 0;
    }
    #spotlight section.card p {
        font-size: 27px;
        /* margin: 38px 0 20px; */
    }
    #spotlight section.card span.button {
        font-size: 24px;
        padding: 20px 74px;
    }
}
@media (max-width: 1150px) {
    #spotlight section.card {
        padding: 37px 0;
    }
}
@media (max-width: 1080px) {
    #spotlight section.card {
        width: 74%;
        padding: 27px 0;
    }
    #spotlight section.card img  {
    
    }
}
@media (max-width: 980px) {
    #spotlight section.card {
        width: 78%;
        padding: 42px 0;
    }
    #spotlight section.card h1 {
        width: 24%;
    }
    #spotlight section.card img {
        width: 42%;
        margin-top: 40px;
    }
    #spotlight section.card p {
        font-size: 26px;
        margin: 11px 0 20px;
    }
    #spotlight section.card span.button {
        font-size: 22px;
    }
}
@media (max-width: 850px) {
    #spotlight section.card {
        width: 78%;
        padding: 38px 0;
    }
    #spotlight section.card h1 {
        width: 28%;
    }
    #spotlight section.card img {
        margin-top: 40px;
        width: 45%;
    }
    #spotlight section.card p {
        font-size: 26px;
        margin: 11px 0 20px;
    }
    #spotlight section.card span.button {
        font-size: 22px;
    }
}
@media (max-width: 768px) {
    #spotlight section.card {
        width: 80%;
        /* padding: 24px 0; */
    }
    #spotlight section.card h1 {
        width: 30%;
    }
    #spotlight section.card img {
        margin-top: 28px;
        width: 48%;
    }
    /* #spotlight section.card p {
        font-size: 24px;
    } */
    /* #spotlight section.card span.button {
        font-size: 19px;
        padding: 20px 50px;
    } */
}
@media (max-width: 640px) {
    #spotlight section.card {
        width: 85%;
        padding: 40px 0;
    }
}
@media (max-width: 607px) {
    #spotlight section.card {
        width: 87%;
    }
    #spotlight section.card p {
        font-size: 21px;
    }
    #spotlight section.card span.button {
        font-size: 18px;
        padding: 18px 41px;
    }
}
@media (max-width: 540px) {
    #spotlight section.card {
        width: 90%;
    }
}
@media (max-width: 511px) {
    #spotlight section.card p {
        font-size: 18px;
        margin: 35px 0 30px;
    }
    #spotlight section.card span.button {
        font-size: 16px;
        padding: 16px 33px;
    }
}
@media (max-width: 465px) {
    #spotlight section.card {
        width: 94%;
    }
}
@media (max-width: 420px) {
    #spotlight section.card {
        width: 95%;
    }
    #spotlight section.card p {
        font-size: 17px;
        margin: 33px 0 28px;
    }
    #spotlight section.card span.button {
        font-size: 14px;
    }
}
@media all and (max-width:480px){
    #spotlight section.card h1 {
        width: 46%;
    }
    #spotlight section.card img {
        width: 58%;
    }
}
@media (max-width: 380px) {
    #spotlight section.card {
        width: 96%;
    }
    #spotlight section.card img {
        margin-top: 34px;
    }
    #spotlight section.card p {
        font-size: 16px;
        margin: 28px 0 23px;
    }
    #spotlight section.card span.button {
        font-size: 13px;
    }
}
@media (max-width: 343px) {
    #spotlight section.card {
        width: 98%;
        padding: 46px 0;
    }
    #spotlight section.card p {
        font-size: 14px;
    }
    #spotlight section.card span.button {
        font-size: 12px;
    }
}

.OtpInput:focus {
    border-bottom: 2px solid #9f51b0 !important;
}
.otp-section div {
    width: 90% !important;
    margin: 0 auto !important;
}
.otp-section div div {
    /* width: 100% !important; */
    display: flex !important;
    justify-content: center !important;
    margin-bottom: 40px !important;
}
.otp-section div div input {
    color: #000 !important;
    font-size: 50px !important;
    border-bottom: 2px solid #c6c6c6 !important;
    display: flex !important;
    justify-content: space-between !important;
}